<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Case Studies</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Case Studies</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-case pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Have a Look Our Work Showcase</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>

        <div class="case">
            <ul class="all-case">
                <li class="active" data-filter="*"><span>All</span></li>
                <li data-filter=".design"><span>Design</span></li>
                <li data-filter=".dev"><span>Development</span></li>
                <li data-filter=".cyber"><span>Cyber Security</span></li>
            </ul>
        </div>

        <div class="row case-list">
            <div class="col-lg-4 col-sm-6 item cyber">
                <div class="single-case">
                    <div class="case-img">
                        <a routerLink="/case-studies-details"><img src="assets/images/case/c1.jpg" alt="case"/></a>
                    </div>

                    <div class="content">
                        <a routerLink="/case-studies-details"><h3>Joe’s Company Software Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                        <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 item dev design">
                <div class="single-case">
                    <div class="case-img">
                        <a routerLink="/case-studies-details"><img src="assets/images/case/c2.jpg" alt="case"/></a>
                    </div>

                    <div class="content">
                        <a routerLink="/case-studies-details"><h3>Ride Share App UX Studies & Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                        <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 item dev">
                <div class="single-case">
                    <div class="case-img">
                        <a routerLink="/case-studies-details"><img src="assets/images/case/c3.jpg" alt="case"/></a>
                    </div>

                    <div class="content">
                        <a routerLink="/case-studies-details"><h3>Restaurant Management & Web Developing</h3></a>
                        <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                        <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 item dev cyber">
                <div class="single-case">
                    <div class="case-img">
                        <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                    </div>

                    <div class="content">
                        <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                        <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 item dev design">
                <div class="single-case">
                    <div class="case-img">
                        <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case"/></a>
                    </div>

                    <div class="content">
                        <a routerLink="/case-studies-details"><h3>Parking Management & Web Developing</h3></a>
                        <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                        <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 item dev cyber design">
                <div class="single-case">
                    <div class="case-img">
                        <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case"/></a>
                    </div>

                    <div class="content">
                        <a routerLink="/case-studies-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                        <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="case-btn text-center">
            <p>We Have More Amazing Cases. <a routerLink="/case-studies">View More</a></p>
        </div>
    </div>
</section>